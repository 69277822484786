<template>
  <div class="content-section">
    <div class="wrapper">
      <h1 class="mt-6">What can we do for you?</h1>
      <v-container>
        <v-row>
          <v-col>
            <v-icon large class="mt-4">mdi-camera</v-icon>
          </v-col>
          <v-col>
            <v-icon large class="mt-4">mdi-camera-image</v-icon>
          </v-col>

          <v-col>
            <v-icon large class="mt-4">mdi-video</v-icon>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <h2 class="mt-3">Photo Session</h2>
          </v-col>
          <v-col>
            <h2 class="mt-3">Photo Retouch</h2>
          </v-col>
          <v-col>
            <h2 class="mt-3">Video</h2>
          </v-col>
        </v-row>

        <v-row>
          <v-col>
            <p>Still frames before, during, or after the wedding.</p>
          </v-col>
          <v-col>
            <p>Photo editing to better realize the subjects of a photo</p>
          </v-col>
          <v-col>
            <p>A living memory of the big day</p>
          </v-col>
        </v-row>
      </v-container>
    </div>
  </div>
</template>

<script>
export default {
  name: "doForYou",
};
</script>

<style>
.wrapper {
  text-align: center;
}
@media only screen and (min-width: 1232px) {
  .wrapper {
    padding: 150px;
    box-shadow: black;
    /* background-color: #e0d4c1; */
    /* background-color: #726a46; */
  }
}
</style>