<template>
  <div class="insta-vue">
    <div
      @click.prevent="link(post.url)"
      class="post"
      :style="`${links ? 'cursor: pointer;' : 'cursor: default;'} width: ${
        100 / cols
      }%;`"
      v-for="post in posts"
      :key="post.id"
    >
      <v-img aspect-ratio="1" :alt="post.alt" :src="post.src" />
      <p class="description" v-if="descriptions">{{ post.description }}</p>
    </div>
  </div>
</template>


<script>
export default {
  name: "InstaVue",
  props: {
    tag: {
      type: String,
      required: true,
    },
    quantity: {
      type: Number,
      default: 4,
    },
    columns: {
      type: Number,
      default: 4,
    },
    links: {
      type: Boolean,
      default: false,
    },
    descriptions: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      posts: [],
      cols: this.columns,
    };
  },
  mounted() {
    this.getPosts();
    if (screen.width < 1232) {
      this.cols = 1;
    }
  },
  methods: {
    link(url) {
      if (this.links) {
        window.open(url, "_blank");
      }
    },
    getPosts() {
      if (this.tag[0] === "#") {
        // all of this block was designed to get all posts for a certain hashtag
        fetch(
          `https://www.instagram.com/explore/tags/${this.tag.replace(
            "#",
            ""
          )}/?__a=1`
        ).then((response) => {
          response.json().then((data) => {
            if (Object.prototype.hasOwnProperty.call(data, "graphql")) {
              for (let i = 0; i < this.quantity; i++) {
                let post =
                  data.graphql.hashtag.edge_hashtag_to_media.edges[i].node;
                this.posts.push({
                  id: post.id,
                  src: post.display_url,
                  url: `https://www.instagram.com/p/${post.shortcode}/`,
                  alt: post.accessibility_caption,
                  description:
                    post.edge_media_to_caption.edges[0]["node"]["text"],
                });
              }
            }
          });
        });
      } else {
        // this block get's posts from a specific user
        fetch(`https://www.instagram.com/${this.tag}/?__a=1`).then(
          (response) => {
            response.json().then((data) => {
              if (Object.prototype.hasOwnProperty.call(data, "graphql")) {
                for (
                  let i = 0;
                  i <
                  Math.min(
                    data.graphql.user.edge_owner_to_timeline_media.edges.length,
                    this.quantity
                  );
                  i++
                ) {
                  let post =
                    data.graphql.user.edge_owner_to_timeline_media.edges[i]
                      .node;
                  this.posts.push({
                    id: post.id,
                    src: post.display_url,
                    url: `https://www.instagram.com/p/${post.shortcode}/`,
                    alt: post.accessibility_caption,
                    description:
                      post.edge_media_to_caption.edges[0]["node"]["text"],
                  });
                }
              }
            });
          }
        );
      }
    },
  },
};
</script>


<style scoped>
@media only screen and (min-width: 1232px) {
  .insta-vue {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
  }
  .insta-vue .post {
    display: block;
    box-sizing: border-box;
    padding: 10px;
    height: auto;
  }
  .insta-vue .post img {
    width: 100%;
  }
  .insta-vue p {
    margin-top: 0.5rem;
    width: 100%;
    font-size: 0.9rem;
    color: #fff;
    mix-blend-mode: difference;
  }
}
</style>