<template>
  <div class="content-section">
    <ImageSlider id="thisSlider" />

    <do-for-you />

    <v-parallax src="@/assets/images/AEI_2492.jpg"> </v-parallax>

    <about-us />

    <v-parallax src="@/assets/images/AEI_1918.jpg"></v-parallax>

    <contact-us />
  </div>
</template>

<script>
import ImageSlider from "@/components/ImageSlider.vue";
import doForYou from "@/components/doForYou.vue";
import aboutUs from "@/components/aboutUs.vue";
import contactUs from "@/components/contactUs.vue";

export default {
  name: "Home",
  components: {
    ImageSlider,
    doForYou,
    aboutUs,
    contactUs,
  },
};
</script>

<style scoped>
@media only screen and (min-width: 1232px) {
  .content-section {
    text-align: center;
  }
  #thisSlider {
    z-index: 100;
    box-shadow: 2px 2px 5px 7px rgba(0, 0, 0, 0.2);
  }

  .center {
    margin: 100px 300px;
  }
}
</style>
