<template>
  <v-app>
    <simpleHeader id="thisHeader" />
    <div class="bumper"></div>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
import simpleHeader from "@/components/SimpleHeader.vue";
export default {
  name: "App",
  components: {
    simpleHeader: simpleHeader,
  },
};
</script>

<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Kanit:wght@100&display=swap");
/* @import url("https://fonts.googleapis.com/css2?family=Antic+Slab&display=swap"); */

* {
  /* margin: 0; */
  font-family: "Kanit", sans-serif;
  /* font-family: "Antic Slab", serif; */
}

#thisHeader {
  width: 100%;
}

@media only screen and (min-width: 1232px) {
  .bumper {
    height: 70px;
  }
}
</style>