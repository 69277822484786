<template>
  <div class="center py-5">
    <div>
      <v-container>
        <v-row>
          <v-col>
            <h1 style="color: black">About Us</h1>
          </v-col>
        </v-row>
      </v-container>
    </div>
    <h2>
      With over 15 years of photography and videography experience, our team is
      ready to capture all of your special moments with ease.
    </h2>
    <br /><br />
    <h1>In studio and on location shoots for</h1>
    <v-sheet v-if="!mobile">
      <v-slide-group v-model="model" show-arrows="always">
        <v-slide-item v-for="item in items" :key="item">
          <v-card class="pa-2 ma-2 pa-l-7 ma-l-2">
            <h2>{{ item }}</h2>
          </v-card>
        </v-slide-item>
      </v-slide-group>
    </v-sheet>
    <v-container v-else style="height: 40vh; overflow: scroll">
      <v-card v-for="item in items" :key="item" class="ma-3 pa-3">
        <h2>{{ item }}</h2>
      </v-card>
    </v-container>
    <h2>
      All your Wedding and Events Photography & Videography needs in one
      place!<br /><br />
    </h2>
  </div>
</template>

<script>
export default {
  name: "aboutUs",
  data() {
    return {
      model: null,
      items: [
        "Weddings",
        "Sweet 16's",
        "Corporate Events",
        "Live Performances",
        "Concerts",
        "Red Carpets",
        "Portraits",
        "Engagements",
        "Graduation",
        "Maternity",
        "Newborn",
        "Headshots",
        "Brand",
        "Advertising",
        "Glamour",
        "Fashion",
        "Modeling",
      ],
      mobile: null,
    };
  },
  created() {
    this.mobile = screen.width > 800 ? false : true;
  },
};
</script>

<style>
.center {
  text-align: center;
}
</style>