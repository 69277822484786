<template>
  <vue-flux
    id="slider"
    :options="vfOptions"
    :images="vfImages"
    :transitions="vfTransitions"
    ref="slider"
  >
    <template v-slot:controls>
      <flux-controls />
    </template>
  </vue-flux>

  <!-- <button @click="$refs.slider.show('next')">NEXT</button> -->
</template>

<script>
import { VueFlux, FluxControls } from "vue-flux";

export default {
  name: "ImageSlider",
  components: {
    VueFlux,
    FluxControls,
  },
  data: () => ({
    vfOptions: {
      autoplay: true,
    },
    vfImages: [
      require("@/assets/SliderImages/AEI_1791.jpg"),
      require("@/assets/SliderImages/AEI_3238.jpg"),
      require("@/assets/SliderImages/AEI_3117.jpg"),
    ],
    vfTransitions: ["swipe"],
  }),
};
</script>

<style>
#slider {
  width: 100%;
}
</style>