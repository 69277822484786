<template>
  <div class="ig">
    <h1 class="mt-10 mb-3">Recent Instagram Posts</h1>
    <hr class="mb-5" style="width: 50%; margin: auto" />

    <insta-vue tag="visuallyforever" :quantity="2000" :columns="3" />
    <v-btn href="https://www.instagram.com/visuallyforever/" class="ma-7"
      >See more in Instagram.com</v-btn
    >
  </div>
</template>

<script>
import InstaVue from "@/components/InstaVue.vue";
export default {
  name: "InstagramGallery",
  components: {
    InstaVue,
  },
};
</script>

<style>
.ig {
  text-align: center;
}
</style>