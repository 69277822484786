<template>
  <div class="outer">
    <v-card class="pt-4 center">
      <h1 class="mb-4">Drop us a message</h1>
      <v-form id="my-form" @submit.prevent="sendEmail">
        <v-container>
          <v-row justify="center">
            <v-col cols="8">
              <v-text-field
                v-model="name"
                :counter="10"
                label="What's your name?"
                name="name"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="8">
              <v-text-field
                v-model="email"
                label="E-mail"
                name="email"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="8">
              <v-text-field
                v-model="phone"
                label="Phone Number"
                name="phone"
                required
              ></v-text-field>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-col cols="8">
              <v-textarea
                v-model="message"
                name="message"
                label="Message"
                hint="(Optional Message)"
              ></v-textarea>
            </v-col>
          </v-row>

          <v-row justify="center">
            <v-card-actions>
              <v-btn type="submit" @click="messageDone"> Done </v-btn>
            </v-card-actions>
          </v-row>
        </v-container>
      </v-form>
      <v-overlay :value="overlay">
        <v-card class="pa-10 messageSent" color="rgb(195, 240, 252)">
          <h1>Message Sent!</h1>
          <p>We will contact you as soon as possible!</p>
          <v-btn @click="clearForm">Okay!</v-btn>
        </v-card>
      </v-overlay>
    </v-card>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
export default {
  name: "contactUs",
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      message: "",
      overlay: false,
    };
  },
  methods: {
    sendEmail: (e) => {
      emailjs
        .sendForm(
          "service_eohxxow",
          "template_pvt4p1m",
          e.target,
          "user_Vcov20N3ANpqIylZvVL6F"
        )
        .then(
          (result) => {
            console.log("Success...", result);
          },
          (error) => {
            console.log("FAILED...", error);
          }
        );
    },
    messageDone() {
      this.overlay = true;
    },
    clearForm() {
      this.name = "";
      this.email = "";
      this.phone = "";
      this.message = "";
      this.phone = "";
      this.overlay = false;
    },
  },
};
</script>

<style>
.messageSent h1,
p {
  color: rgb(76, 128, 190);
}

@media only screen and (min-width: 1232px) {
  .outer {
    padding: 100px;
    width: 50%;
    margin: auto;
  }
}
</style>